import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const WhoWeAre = () => (
  <>
    <Metatags
      title="daucodesigns | política de privacidad"
      description="detrás de cada producto, hay una historia"
      url="https://daucodesigns.com"
      image="https://daucodesigns.com/logo-daucodesigns.png"
      keywords="muebles, design"
    />
    <Navbar />
    <div className="height40" />

    <Container
      style={{ marginTop: "60px", maxWidth: "1300px" }}
      className="quienes-somos"
    >
      <Row>
        <Col>
          <p>&nbsp;</p>
          <h3>Política de Privacidad</h3>
          <h5>Política de privacidad y confidencialidad</h5>
          <p>&nbsp;</p>
          <p>
            <b>Dauco Designs, S.L.</b> (en adelante Dauco Designs) informa a los
            usuarios de este servicio web y de nuestras tiendas acerca de la
            política de protección de datos de carácter personal para que estos
            determinen libre y voluntariamente si desean facilitarnos los Datos
            Personales que se les puedan requerir o que se puedan obtener con
            ocasión de la suscripción o alta en algunos de los servicios
            ofrecidos.
          </p>
          <p>
            De conformidad con lo establecido por la Ley Orgánica 15/1999, de
            Protección de Datos de Carácter Personal, Dauco Designs informa que
            los datos personales facilitados a través de los formularios de esta
            web, mediante mensajes de correo electrónico o como consecuencia de
            cualquier transacción u operación realizada, serán objeto de
            tratamiento y recogidos en un fichero de datos, dando el remitente
            su consentimiento para ser incluido en el mencionado fichero.
          </p>
          <p>
            La finalidad de este fichero es servir como soporte de información a
            la gestión administrativa y comercial de la empresa.
          </p>
          <p>
            El usuario de este servicio reconoce que la información y los datos
            personales suministrados son exactos y veraces. En este sentido, se
            compromete a comunicar de forma inmediata cualquier modificación de
            sus datos de carácter personal a fin de que la información contenida
            en el mencionado fichero esté en todo momento actualizada y no
            contenga errores.
          </p>
          <p>&nbsp;</p>
          <h5>Consentimiento del usuario</h5>
          <p>&nbsp;</p>
          <p>
            El envío de datos personales, mediante el uso de los formularios
            electrónicos de Dauco Designs, mediante mensajes de correo
            electrónico o como consecuencia de cualquier transacción u operación
            realizada, supone el consentimiento expreso del remitente al
            tratamiento automatizado de los datos incluidos en los medios de
            comunicación indicados, servicios y productos relacionados con las
            finalidades de los sitios web de Dauco Designs, así como al envío de
            comunicaciones vía electrónica con información relacionada con Dauco
            Designs y sus iniciativas.
          </p>
          <p>
            A los efectos del artículo 21 de la Ley 34/2002 de Servicios de la
            Sociedad de la Información, el usuario presta su consentimiento para
            el envío de comunicaciones comerciales por parte de Dauco Designs a
            la dirección de correo electrónico indicada inicialmente en el
            registro para el acceso a los servicios prestados por Dauco Designs
            o a cualquier modificación posterior de la misma, pudiendo oponerse
            en todo momento al envío de tales comunicaciones mediante solicitud
            a través de canales específicamente habilitados para ello. Estas
            comunicaciones comerciales, que siempre serán realizadas por Dauco
            Designs, podrán contener información sobre productos y/o servicios
            comercializados directamente por esta última o bien relativos a
            terceros con los que medie algún tipo de relación, acuerdo o
            contrato.
          </p>
          <p>&nbsp;</p>
          <h5>
            Derechos de acceso, rectificación, oposición y cancelación de los
            usuarios.
          </h5>
          <p>&nbsp;</p>
          <p>
            Si lo desea puede dirigirse a Dauco Designs, en Gregorio Benítez,
            16, Local 2, 28043 - Madrid o enviar un e-mail a
            lopd@daucodesigns.com, con el fin de ejercer los derechos de acceso,
            rectificación, cancelación y oposición.
          </p>
          <p>&nbsp;</p>
          <h5>Seguridad</h5>
          <p>&nbsp;</p>
          <p>
            Dauco Designs adopta para el tratamiento de los datos, todas las
            medidas técnicas y organizativas de seguridad que sean de
            obligación, de conformidad con lo establecido por la legislación
            vigente, y ha establecido todos los medios técnicos a su alcance
            para evitar la pérdida, mal uso, alteración, acceso no autorizado y
            robo de los datos que el usuario facilite a través del sitio web,
            sin perjuicio de informarle de que las medidas de seguridad en
            Internet no son inexpugnables.
          </p>
          <p>
            Dauco Designs se compromete a cumplir con el deber de secreto y
            confidencialidad respecto de los datos personales contenidos en el
            fichero automatizado de acuerdo con la legislación aplicable, así
            como a conferirles un tratamiento seguro en las cesiones y
            transferencias de datos que, en su caso, puedan producirse.
          </p>
          <p>
            Ejemplo de esta cesión son los datos personales necesarios para que
            la agencia de transportes pueda realizar la entrega o a la entidad
            de crédito prestadora de los servicios del pago dentro del marco de
            la gestión del pago.
          </p>
          <p>&nbsp;</p>
          <h5>Cookies e IP’s</h5>
          <p>&nbsp;</p>
          <p>
            El usuario acepta el uso de cookies y seguimiento de IP’s. Nuestro
            analizador de tráfico del site utiliza cookies y seguimiento de IP’s
            que nos permiten recoger datos a efectos estadísticos como: fecha de
            la primera visita, número de veces que se ha visitado, fecha de la
            última visita, URL y dominio de la que proviene, explorador
            utilizado y resolución de la pantalla. No obstante, el usuario si lo
            desea puede desactivar y/o eliminar estas cookies siguiendo las
            instrucciones de su navegador de Internet.
          </p>
          <p>
            Dauco Designs no utiliza técnicas de "spamming" y únicamente tratará
            los datos que el usuario transmita mediante los formularios
            electrónicos habilitados en este sitio web o mensajes de correo
            electrónico.
          </p>
          <p>&nbsp;</p>
          <h5>Modificación de la presente política de privacidad</h5>
          <p>&nbsp;</p>
          <p>
            Dauco Designs se reserva el derecho a modificar la presente política
            de privacidad para adaptarla a futuras novedades legislativas o
            jurisprudenciales, así como a prácticas de la industria.
          </p>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <div className="height40" />
    <Footer />
  </>
)

export default WhoWeAre
